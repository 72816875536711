.wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .svg {
        flex: 2;
    }

    .links {
        flex: 1;
        display: flex;
        flex-direction: row;
        gap: var(--space-base);
        justify-content: space-between;
        transition: transform 0.5s ease-in-out;
        list-style-type: none;
    }

    .link {
        padding-bottom: var(--space-xs);
        position: relative;
        display: block;
        padding: 4px 0;
        text-decoration: none;
        transition: 0.5s;

        &::after {
            position: absolute;
            content: "";
            top: 100%;
            left: 0;
            width: 100%;
            height: 3px;
            background: var(--color-two);
            transform: scaleX(0);
            transform-origin: right;
            transition: transform 0.5s;
        }

        &:hover::after {
            transform: scaleX(1);
            transform-origin: left;
        }
    }

    .active {
        position: relative;
        display: block;
        padding: 4px 0;
        text-decoration: none;
        border-color: var(--color-two);
        border-bottom-width: 3px;
    }
}

