.wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 100px;
    .subtext {
        text-align: center;
    }

    .reverse {
        font-size: var(--font-size-header-1);
        line-height: var(--line-height-header-1);
        font-weight: 700;
        color: transparent;
        -webkit-text-stroke-width: 1px;
        -webkit-text-stroke-color: var(--color-two);
        font-family: var(--font-stack-title);
        letter-spacing: 2px;
    }
}
