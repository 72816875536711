.wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    .card {
        z-index: 1;
        grid-column: 1 / 4;
        grid-row: 1;
        background-image: url('../../../../public/img/footer.png');
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 66px;

        .cardHeader {
            margin-bottom: 1.5rem;
            justify-content: space-between;
            display: flex;
            align-items: center;
            gap: 10px;
            justify-self: flex-start;
            align-self: flex-start;
            .cardDot {
                width: 5px;
                height: 5px;
                background-color: var(--color-two);
                border-radius: 50px;
            }
        }
    }
    .image {
        z-index: -1;
        align-self: center;
        grid-column-start: 3;
        grid-column-end: 5;
        grid-row: 1;
        margin-left: 240px;
    }
}
