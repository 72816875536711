.wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    right: 3%;
    bottom: 20%;
    cursor: pointer;
    width: 61px;
    height: 61px;
    border-radius: 200px;
    border-width: 1px;
    border-color: var(--color-secondary);
}
.isHidden {
    display: none;
}
