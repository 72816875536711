.bold {
    font-weight: var(--font-bold);
}

.italic {
    font-style: italic;
}

.space {
    margin-top: 30px;
}

.space2 {
    margin-top: 40px;
}

.container {
    display: flex;
    gap: 100px;
    flex-direction: row;
    align-items: center;
    .imageScreener {
        width: 500px;
        height: 500px;
    }
}
