@import '../../styles/_mixins.scss';

.wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .intro {
        display: flex;
        width: 100%;
        flex-direction: row;
        justify-content: space-between;
        .introTextBlock {
            flex: 2;
            justify-content: flex-start;
            @include desktop-xl {
                justify-content: center;
            }

            display: flex;
            flex-direction: column;

            .reverseText {
                color: var(--color-one);
                -webkit-text-fill-color: transparent;
                -webkit-text-stroke-width: 1px;
                -webkit-text-stroke-color: var(--color-two);
            }
            .presentation {
                padding-top: var(--space-xl);
                padding-bottom: 4rem;
                padding-right: 20%;
                line-height: initial !important;
            }
        }
        .introImageBlock {
            flex: 1;
            width: 100px;
            border-radius: 5px;
            box-shadow: 8px 8px 0px 0px var(--color-two);
        }
    }

    .block {
        margin-top: 10rem;
        margin-bottom: 10rem;
    }
    .block2 {
        margin-top: 10rem;
    }

    .txt {
        margin-top: 1rem;
        margin-bottom: 1rem;
    }

    .research {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .isReady {
        pointer-events: none;
    }

    .arrow {
        margin-top: var(--space-lg);
        margin-bottom: var(--space-lg);
    }
    .cta {
        align-self: center;
    }
}


