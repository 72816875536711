@mixin headerStyle($fontSize, $lineHeight) {
  font-size: $fontSize;
  line-height: $lineHeight;
  font-weight: 700;
  color: var(--color-two);
  font-family: var(--font-stack-title);
  letter-spacing: 2px;
}

* {
  font-family: var(--font-stack-body);
}

.header1 {
  @include headerStyle(var(--font-size-header-1), var(--line-height-header-1));
}

.header2 {
  @include headerStyle(var(--font-size-header-2), var(--line-height-header-2));
}

.header3 {
  @include headerStyle(var(--font-size-header-3), var(--line-height-header-auto));
}

.header4 {
  @include headerStyle(var(--font-size-header-4), var(--line-height-header-auto));
}

.header5 {
  @include headerStyle(var(--font-size-header-5), var(--line-height-header-auto));
}

.body {
  color: var(--color-two);
  font-weight: var(--font-light);
  font-size: var(--font-size-body);
}

.blocText {
  font-family: var(--font-stack-title);
  font-size: 70px;
  font-style: normal;
  line-height: 95px;
  color: var(--color-two);
  letter-spacing: 1.5px;
}

.clientName {
  font-family: var(--font-stack-body);
  font-weight: 300;
  font-size: 20px;
  letter-spacing: 1px;
}

.titleStudy1 {
  font-family: var(--font-stack-body);
  font-weight: 550;
}

.legend {
  font-family: var(--font-stack-body);
  font-weight: 150;
}


.bodyCard {
  color: var(--color-two);
  font-weight: var(--font-light);
  font-size: 19px;
}

.bodyBold {

}

.bodyLink {

}
.quote {

}
.titleStudy2 {

}
.projectTitle {

}
.number {
  font-family: var(--font-stack-body);
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
}
.icon {

}
