.name {
  color: var(--color-two);
  font-family: var(--font-stack-title);
  font-weight: 550;
  text-decoration: underline;
  text-decoration-color: var(--color-two);
}

.container {
    display: flex;
    height: 100%;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    .line {
        width: 100px;
        height: 5px;
        border-radius: 50px;
        background-color: var(--color-two);
    }
}
