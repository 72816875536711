:root {
  --color-one: #E5E2DF;
  --color-two: #353535;

  --font-light: 270;
  --font-medium: 500;
  --font-bold: 600;

  --font-size-header-1: 6.9rem;
  --font-size-header-2: 4.75rem;
  --font-size-header-3: 3.625rem;
  --font-size-header-4: 2.5rem;
  --font-size-header-5: 1.5rem;
  --font-size-body: 1.438rem;
  --font-size-button: 1.275rem;
  --font-size-bloc-text: 3.375rem;

  --line-height-header-1: 6.5rem;
  --line-height-header-2: 5.375rem;
  --line-height-body: 0.875rem;
  --line-height-header-auto: auto;
  --line-height-bloc-text: 4.781rem;

  --font-stack-body: 'Josefin Sans', sans-serif;
  --font-stack-title: 'Forma DJR Banner', sans-serif;

  --space-xxs: 0.25rem;
  --space-xs: 0.5rem;
  --space-sm: 0.75rem;
  --space-base: 1rem;
  --space-md: 1.25rem;
  --space-lg: 2rem;
  --space-xl: 3.125rem;
  --space-xxl: 6rem;
  --space-xxxl: 20rem;
}
