.cardWrapper {
    cursor: pointer;
    width: calc(50% - 50px);
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-bottom: 3rem;
    .cardImgNotReady {
        width: fit-content;
        opacity: 0.3;
        filter: alpha(opacity=20); /* msie */
    }

    .cardImg {
        width: fit-content;
        --c: var(--color-two); /* the border color */
        --b: 1px;    /* the border thickness*/
        --g: 9px;     /* the gap on hover */

        padding: calc(var(--g) + var(--b));
        --_g: #0000 25%,var(--c) 0;
        background:
            conic-gradient(from 180deg at top var(--b) right var(--b),var(--_g))
            var(--_i,200%) 0  /200% var(--_i,var(--b))  no-repeat,
            conic-gradient(at bottom var(--b) left  var(--b),var(--_g))
                          0 var(--_i,200%)/var(--_i,var(--b)) 200%  no-repeat;

    }
    .cardImg:hover {
        --_i: 100%;
        transition: 0.5s, background-size 0.5s 0.5s;
    }
    .cardOther {
        margin-left: 11px;
    }
}

.isNotReady {
    cursor: not-allowed;
}
