.paragraph {
    margin: 5% 10%;
}

.bold {
    color: var(--color-two);
    font-weight: var(--font-bold);
}

.paragraphSummary {
    margin-top: 8rem;
}

.paragraphSpace {
    margin-top: var(--space-lg);
}

.paragraphSpace2 {
    margin-top: 100px;
}

.titleCard {
    margin-bottom: 20px;
}

.paragraphSpace3 {
    margin-top: 60px;
}

.textSpace {
    margin-top: var(--space-md);
}

.imageScreener {
    margin: var(--space-xl) auto 0 auto;
}

.tab {
    margin-left: var(--space-xl);
}

.tab2 {
    margin-left: 10px;
}
.legendScreener {
    margin: 0 auto var(--space-xl) auto;
}

.itemList2 {
    display: flex;
    align-items: baseline;
    gap: 30px;
    margin-top: 10px;
}

.itemList {
    display: flex;
    align-items: baseline;
    gap: 30px;
    margin-top: var(--space-lg);
}

.dot {
    padding: 4px;
    background-color: var(--color-two);
    border-radius: 50px;
}
.dot2 {
    padding: 3px;
    background-color: var(--color-two);
    border-radius: 50px;
}

.cardExample {
    margin-top: var(--space-xl);
    background-color: var(--color-one);
    padding: var(--space-lg);
    border-radius: 15px;
    border-color: var(--color-two);
    border-width: 1px;
    border-style: solid;
    box-shadow: 8px 10px 0px 0px var(--color-two);
}
.testUserPlan {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: min-content min-content 1fr min-content min-content;
    grid-template-areas:
    "testProduct tasks tasks"
    "testGoal tasks tasks"
    "testGoal equipment users "
    "responsability datetime datetime"
    "responsability steps steps";


    margin-top: 20px;
    padding: var(--space-lg);
    column-gap: 5px;
    background-image: url('../../../public/img/footer.png');
    border-radius: 15px;

    div {
        border-radius: 15px;
        padding: var(--space-md);
        margin: var(--space-md);
        background-color: #f4f0ed;
    }
    .testProduct {
        grid-area: testProduct;
    }
    .testGoal {
        grid-area: testGoal;
    }
    .users {
        grid-area: users;
    }
    .tasks {
        grid-area: tasks;
        ul {
            margin-left: var(--space-lg);
            .itemListCard {
                display: flex;
                flex-direction: row;
                align-items: baseline;
                gap: 20px;
                margin-bottom: var(--space-lg);
            }
        }
    }
    .equipment {
        grid-area: equipment;
        height: min-content;
    }
    .responsability {
        grid-area: responsability;
        height: min-content;
    }
    .datetime {
        grid-area: datetime;
        height: min-content;
    }
    .steps {
        grid-area: steps;
        display: flex;
        flex-direction: column;
        // justify-content: space-evenly;
        // align-items: center;
        h6 {
            // align-self: flex-start;
        }
    }
}

.wireframes {
    display: flex;
    flex-direction: row;
}

.link {
    text-decoration-line: underline;
}

.linkContainer {
    a:visited {
        color: purple;
    }
}
