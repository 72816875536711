.wrapper {
    background-color: var(--color-two);
    color: var(--color-one);
    display: flex;
    height: max-content;
    padding: 5% 10%;

    .steps {
        flex: 3;
        display: flex;
        flex-wrap: wrap;
        width: fit-content;
        justify-content: space-between;
        align-content: center;
        gap: 1rem;
        .step {
            width: calc(50% - 20px);
            .stepValue {
                color: var(--color-one);
                width: 80%;
                font-weight: 300;
            }
            .stepNumber {
                margin-bottom: 10px;
            }
        }
    }
    .others {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 30px;
        * {
            color: var(--color-one);
            font-weight: 400;
        }
        .value {
            font-weight: 300;
        }


    }
}
