.wrapper {
    font-family: var(--font-stack-body);
    font-weight: var(--font-light);
    display: flex;
    color: var(--color-two);
    font-size: var(--font-size-button);
    border-width: 1px;
    border-color: var(--color-two);
    border-radius: 5px;
    border-style: solid;
    justify-content: center;
    width: max-content;
    height: 60px;
    padding: var(--space-xs) var(--space-lg);
    transition: 0.90s;
};

.wrapper:hover,
.wrapper:focus {
    box-shadow: inset 500px 0 0 0 var(--color-two);
    color: var(--color-one);
}

button, button:active, button:focus {
    outline: none !important;
}
