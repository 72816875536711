@include spacingUtils();
@include flexUtils();


html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent;
  font-family: var(--font-stack-primary);
  height: 100%;
}

body {
  min-height: 100%;
  height: 100%;
  background-color: #f0f0f0;
  background-image: url('../../public/img/background.png');
  background-repeat: repeat;
}

#root {
  min-height: 100%;
  height: 100%;
}
