.wrapper {
	display: inline-flex;
}

$styles: (
  outline: stroke,
  solid: fill,
);

$colors: (
  primary: var(--color-one),
  secondary: var(--color-two),
);

.large {
  transform: scale(2) translate(-25%, -25%) !important;
}

@each $style-key, $style-value in $styles {
  @each $color-key, $color-value in $colors {
    .#{$style-key}-#{$color-key} {
      path {
        #{$style-value}: $color-value;
      }
    }
  }
}
