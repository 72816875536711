.wrapper {
    position: relative;
    display: grid;
    height: 100%;
    grid-template-columns: 10% 1fr 10%;
    grid-template-rows: min-content 1fr min-content;
    flex-grow: 1;
    row-gap: 8rem;
    padding-top: var(--space-lg);
    .menu {
        grid-column: 2 / 3;
        grid-row: 1;
    }
    .content {
        grid-column: 2;
        grid-row: 2;
    }
    .projectContent {
        grid-column: 1 / 4;
        grid-row: 2;
    }
    .hideContent {
        display: none;
    }
    .footer {
        width: 100%;
        align-items: center;
        height: 70px;
        grid-column: 1 / 4;
        grid-row: 3;
        display: flex;
        justify-content: center;
        background-image: url('../../../../public/img/footer.png');
        svg:hover g :first-child{
            fill: #0077b5;
        }

    }
}
